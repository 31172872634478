import React from 'react';
import { Trans } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { CakeUtil } from '@cakedefi/cake-sdk';
import appConfig from 'config/app';
import GetTheMobileApp from 'views/components/GetTheMobileApp';
import accessIndustryLogo from '../../../assets/svg/logos/access-industry-member.svg';
import i18n from '../../../i18n';
import { getBlogBaseLink } from '../../../utils/getBlogLinks';
import { getZendeskBaseLink, getZendeskRequestLink } from '../../../utils/getZendeskLinks';
import getCakeDeFiWebsiteBaseURL from '../../../utils/getCakeDeFiWebsiteBaseURL';
import styles from './Footer.styles';
import HeaderLogo from './HeaderLogo';

const useStyles = createUseStyles(styles);

export default function Footer({ canShowMenu }) {
  const classes = useStyles();
  // const lending = useCakeSelector(userAccessRightSelector(AccessRights.Lending));
  const isEligibleForSub = appConfig.SUBSCRIPTION_SERVICE;

  const getCakeDeFiFooterLink = (slug: string) => `${getCakeDeFiWebsiteBaseURL()}/${slug}`;

  return (
    <footer>
      <div className={classes.footerContainer}>
        {
          canShowMenu && (
            <div className={classes.footerLinksContainer}>
              <div className={classes.footerLinks}>
                <h4><Trans>Bake & Boost</Trans></h4>
                <ul>
                  {isEligibleForSub && (
                    <li>
                      <Link to="/pro" className="link"><Trans>Bake Pro</Trans></Link>
                    </li>
                  )}
                  <li>
                    <Link to="/lending" className="link"><Trans>Lending</Trans></Link>
                  </li>
                  <li>
                    <Link to="/liquidity-mining" className="link"><Trans>Liquidity Mining</Trans></Link>
                  </li>
                  <li>
                    <Link to="/staking" className="link"><Trans>Staking</Trans></Link>
                  </li>
                  <li>
                    <Link to="/referral" className="link"><Trans>Referral</Trans></Link>
                  </li>
                  <li>
                    <Link to="/learn" className="link"><Trans>Learn</Trans></Link>
                  </li>
                </ul>
              </div>
              <div className={classes.footerLinks}>
                <h4><Trans>Support & Legal</Trans></h4>
                <ul>
                  <li>
                    <a target="_blank" rel='noopener noreferrer' className="link" href={getZendeskBaseLink(i18n.language)}>
                      <Trans>FAQ</Trans>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel='noopener noreferrer' className="link" href={getZendeskRequestLink(i18n.language)}>
                      <Trans>Submit a request</Trans>
                    </a>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions" className="link"><Trans>Terms and Conditions</Trans></Link>
                  </li>
                  <li>
                    <a href={getCakeDeFiFooterLink('privacy-policy')} className="link"><Trans>Privacy Policy</Trans></a>
                  </li>
                  <li>
                    <a href={getCakeDeFiFooterLink('legal-information')}><Trans>Legal Information</Trans></a>
                  </li>
                </ul>
              </div>
              <div className={classes.footerLinks}>
                <h4><Trans>We’re Bake</Trans></h4>
                <ul>
                  <li>
                    <a href={getCakeDeFiFooterLink('about-us')}><Trans>About Us</Trans></a>
                  </li>
                  <li>
                    <a href={getCakeDeFiFooterLink('media-center')}><Trans>Media Center</Trans></a>
                  </li>
                  <li>
                    <a href={getBlogBaseLink(i18n.language)}><Trans>Blog</Trans></a>
                  </li>
                  <li>
                    <a href={getCakeDeFiFooterLink('careers')}><Trans>Careers</Trans></a>
                  </li>
                  <li>
                    <a href={getCakeDeFiFooterLink('security')}><Trans>Security</Trans></a>
                  </li>
                </ul>
              </div>
              <div className={classes.footerLinks}>
                <h4><Trans>Community</Trans></h4>
                <ul>
                  <li>
                    <a href={CakeUtil.getSocialMediaLinks('facebook')} target="_blank" rel='noopener noreferrer'><Trans>Facebook</Trans></a>
                  </li>
                  <li>
                    <a href={CakeUtil.getSocialMediaLinks('reddit')} target="_blank" rel='noopener noreferrer'><Trans>Reddit</Trans></a>
                  </li>
                  <li>
                    <a href={CakeUtil.getSocialMediaLinks('linkedin')} target="_blank" rel='noopener noreferrer'><Trans>LinkedIn</Trans></a>
                  </li>
                  <li>
                    <a href="https://bake.myspreadshop.com/" target="_blank" rel='noopener noreferrer'><Trans>Merch
                      (US)</Trans></a>
                  </li>
                </ul>
              </div>
              <div className={classes.footerLinks}>
                <h4 className="invisible"><Trans>Community</Trans></h4>
                <ul>
                  <li>
                    <a href={CakeUtil.getSocialMediaLinks('twitter')} target="_blank" rel='noopener noreferrer'><Trans>Twitter</Trans></a>
                  </li>
                  <li>
                    <a href={CakeUtil.getSocialMediaLinks('youtube')} target="_blank" rel='noopener noreferrer'><Trans>YouTube</Trans></a>
                  </li>
                  <li>
                    <a href={CakeUtil.getTelegramLinkByLanguage(i18n.language)} target="_blank" rel='noopener noreferrer'><Trans>Telegram</Trans></a>
                  </li>
                  <li>
                    <a href="https://bake.myspreadshop.co.uk/" target="_blank" rel='noopener noreferrer'><Trans>Merch
                      (UK)</Trans></a>
                  </li>
                </ul>
              </div>
            </div>
          )
        }
        <div className="row">
          <div className={classes.footerBottomContainer}>
            <div className={classes.footerLogo}>
              <HeaderLogo></HeaderLogo>
            </div>
            <div className={classes.cakeDescriptionContainer}>
              <div className={classes.cakeDescription}>
                <div className={classes.copyright}>
                  &copy; Bake {new Date().getFullYear()}
                </div>
                <div className={classes.cakeUen}>
                  <Trans>&#169; GST {new Date().getFullYear()}.</Trans>
                </div>
                <div className={classes.companyInfoContainer}>
                  <p className={classes.companyInfo}>
                    <Trans>Member of Singapore Fintech Association</Trans>
                  </p>
                  <p className={classes.companyInfo}>
                    <Trans>Member of ACCESS</Trans>
                  </p>
                </div>
              </div>
              <GetTheMobileApp />
              <img className={classes.flexLogo} src={accessIndustryLogo} alt="Access Industry" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
