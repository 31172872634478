import { createSelector } from '@reduxjs/toolkit';
import gtmTrack, { iEventNames, iEventTypes } from 'common/hooks/gtmTrack/gtmTrack';
import appConfig from 'config/app';
import i18next from 'i18next';
import md5 from 'md5';
import 'moment/min/locales.min';
import React from 'react';
import { Trans, Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { canUserAccessToLendingSelector, canUserAccessToSubscriptionSelector } from 'user/userSelector';
import { getLanguageButExcludeGerman } from 'utils/getLanguageButExcludeGerman';
import userDefaultPicture from '../../../assets/img/default-user-picture@2x.png';
import { setSelectedLanguage, updateLanguagePreference } from '../../../user/LanguageRegionSettings/languageRegionSettingsSlice';

import languages from '../../../assets/data/lang.json';
import Bake4TheWinHeaderLink from '../../../campaigns/Bake4TheWin/components/Bake4TheWinHeaderLink';
import BtcPizzaDayHeaderLink from '../../../campaigns/BtcPizzaDay/components/BtcPizzaDayHeaderLink';
import EthLuckyDrawHeaderLink from '../../../campaigns/EthLuckyDraw/components/EthLuckyDrawHeaderLink';
import FireCampaignHeaderLink from '../../../campaigns/FireCampaign/FireCampaignHeaderLink';
import i18n from '../../../i18n';
import { summarizeLMBalanceInUsd } from '../../../liquidity-mining/liquidityMiningUtils';
import { setRedirectOnLogin } from '../../../navigation/navigationSlice';
import { HeaderLink } from '../../../types/header/header-link';
import { setAllLanguages } from '../../../user/LanguageRegionSettings/languageRegionSettingsUtils';
import browserLanguageToi18nLanguage from '../../../utils/browserLangToi18nLang';
import getCakeDeFiWebsiteBaseURL from '../../../utils/getCakeDeFiWebsiteBaseURL';
import getLanguageFromLocalStorage from '../../../utils/getLanguageFromLocalStorage';
import { getZendeskBaseLink } from '../../../utils/getZendeskLinks';
import FiatBuyAtHeader from '../../../wallet/components/FiatBuyAtHeader';
import { summarizeBalanceInUsd } from '../../../wallet/walletService';
import { ClickableElement, DropDown, Icon } from '../../components';
import BasePage from '../BasePage';
import HeaderNavigationLink from './components/HeaderNavigationLink';
import HeaderBanners from './HeaderBanners';
import HeaderDropdown from './HeaderDropdown';
import HeaderLogo from './HeaderLogo';
import Snapchat from './Snapchat';

const PROFILE_TYPE = 'profileOpen';
const MOBILE_MENU_TYPE = 'mobileMenuOpen';

const mapDispatch = { updateLanguagePreference, setSelectedLanguage, setRedirectOnLogin };

const languageRegionSettingsSelector = state => state.languageRegionSettings;
const userDetailsSelector = state => state.user.details;
const currencySelector = state => state.currency;
const balanceSelector = state => state.wallet.balances;
const lmBalanceSelector = state => state.wallet.lmBalances;
const balanceSummarySelector = createSelector(currencySelector, balanceSelector, (currency, balances) => {
  if (currency.coins.pricing.length === 0 || !balances || balances.length === 0) {
    return null;
  }
  // also filter discontinued coins
  const filteredBalances = balances.filter(balance => !balance.coin.discontinued);
  return summarizeBalanceInUsd(filteredBalances, currency.coins.pricing);
});

const lmBalanceSummarySelector = createSelector(currencySelector, lmBalanceSelector, (currency, lmBalances) => {
  if (currency.coins.pricing.length === 0 || !lmBalances || lmBalances.length === 0) {
    return null;
  }
  return summarizeLMBalanceInUsd(lmBalances, currency.coins.pricing);
});

const mapState = state => ({
  languageRegionSettings: languageRegionSettingsSelector(state),
  userDetails: userDetailsSelector(state),
  currency: currencySelector(state),
  balanceSummary: balanceSummarySelector(state),
  lmBalanceSummary: lmBalanceSummarySelector(state),
  canAccessLending: canUserAccessToLendingSelector(state),
  canAccessSubscription: canUserAccessToSubscriptionSelector(state),
});

class Header extends BasePage {
  languageDropDownRef;

  state = {
    profileOpen: false,
    mobileMenuOpen: false,
  };

  setProfileOpen = profileOpen => this.setState({ profileOpen });

  setMobileMenuOpen = mobileMenuOpen => this.setState({ mobileMenuOpen });

  toggleDropDowns = (type, isOpen) => {
    switch (type) {
      case PROFILE_TYPE:
        this.setProfileOpen(isOpen);
        this.setMobileMenuOpen(false);
        break;
      case MOBILE_MENU_TYPE:
        this.setMobileMenuOpen(isOpen);
        this.setProfileOpen(false);
        break;
      default:
        this.setProfileOpen(false);
        this.setMobileMenuOpen(false);
    }
  };

  goTo = (target) => {
    this.closeMenus();
    this.navigate(target);
  };

  closeMenus = () => {
    this.setProfileOpen(false);
    this.setMobileMenuOpen(false);
  }

  static getHeaderLinks(canAccessLending: boolean, canAccessSubscription: boolean) {
    const isEligibleForSub = appConfig.SUBSCRIPTION_SERVICE;
    const cakeELITELink = isEligibleForSub && canAccessSubscription ? new HeaderLink('Bake Pro', '/pro', undefined, undefined, undefined, 'NEW') : undefined;
    const links: HeaderLink[] = [
      new HeaderLink('Services', '#', [
        new HeaderLink('Staking', '/staking', undefined, undefined, undefined, 'NEW'),
        new HeaderLink('Lending', '/lending', undefined, undefined, undefined, 'NEW'),
      ], undefined, undefined, 'NEW'),
      cakeELITELink,
      // new HeaderLink('Freezer', '/freezer'),
      new HeaderLink('Referral', '/referral'),
      new HeaderLink('Learn', '/learn'),
      new HeaderLink('FAQ', getZendeskBaseLink(i18n.language), null, null, null, null, true),
      new HeaderLink('Company', '#', [
        new HeaderLink('Transparency', `${getCakeDeFiWebsiteBaseURL()}/transparency`, null, null, null, null, true),
      ], undefined, undefined),
    ];

    const updatedLinks = links.filter(Boolean); // remove falsy values

    return updatedLinks;
  }

  async logout() {
    this.setProfileOpen(false);
    this.setMobileMenuOpen(false);
    this.closeMenus();
    await this.cakepool.logout();
    window?.location?.reload?.(); // reloading the page as it needs to fetch new data with logged-out status
  }

  async logoutFromDataConsent() {
    this.setProfileOpen(false);
    this.setMobileMenuOpen(false);
    await this.cakepool.logout();
    this.goTo('/login');
    window?.location?.reload?.(); // reloading the page as it needs to fetch new data with logged-out status
  }

  onLanguageChange = async (language) => {
    try {
      this.props.updateLanguagePreference(language);
      this.props.setSelectedLanguage(language);
      this.forceUpdate();
      this.languageDropDownRef.hide();
    } catch (error) {
      const { message } = error;
      this.cakepool.showAlert('error', `Language update failed: ${message}`);
    }
  };

  updateLanguage = async (language: string) => {
    try {
      this.props.updateLanguagePreference(language);
      this.props.setSelectedLanguage(language);
      this.forceUpdate();
    } catch (error) {
      const { message } = error;
      this.cakepool.showAlert('error', `Language update failed: ${message}`);
    }
  }

  componentDidMount() {
    if (!i18n.isInitialized) {
      // listen to i18n to get updated language from url or localstorage (no user log in)
      i18next.on('initialized', () => {
        const currentLanguage = browserLanguageToi18nLanguage(i18n.language);
        const expectedLanguage = getLanguageButExcludeGerman(currentLanguage);
        this.updateLanguage(expectedLanguage);
      });
    } else {
      // where user log in
      const currentLanguage = browserLanguageToi18nLanguage(getLanguageFromLocalStorage());
      this.updateLanguage(currentLanguage);
    }
  }

  componentDidUpdate(prevProps) {
    // take selectedLanguage from redux when user log in
    const { selectedLanguage } = this.props.languageRegionSettings;
    if (prevProps.languageRegionSettings.selectedLanguage !== selectedLanguage) {
      setAllLanguages(selectedLanguage);
    }
  }

  render() {
    const { userDetails: user, canShowMenu, isUserStatusWithdrawal } = this.props;
    return (
      <Translation>
        {() => <React.Fragment>
          <Snapchat />
          <header className="header">
            <div className="header-nav">
              <HeaderLogo></HeaderLogo>
              {
                (canShowMenu && !isUserStatusWithdrawal) && (
                  <div className="main-menu">
                    {/* hide this for signup flow */}
                    {this.renderHeaderLinks()}
                  </div>
                )
              }
            </div>
            {
              canShowMenu ? (
                <div className={`header-actions ${user && 'with-profile'}`}>
                  <div className="fiat-buy-menu">
                    <FiatBuyAtHeader isLoggedIn={!!user} />
                  </div>
                  <div className="mobile-menu" style={{ order: user ? 1 : 3 }}>
                    {this.renderMobileMenu()}
                  </div>
                  {
                    !user && (
                      <div className="desktop-user-menu">
                        {this.renderDesktopHamburgerMenu()}
                      </div>
                    )
                  }
                  <div className="user-menu">
                    {this.renderUserMenu()}
                  </div>
                  <div className="language-menu">
                    {this.renderLanguageSwitch()}
                  </div>
                  <div className="profile-menu">
                    {this.renderProfileMenu()}
                  </div>
                </div>
              ) : (
                <div className="language-logout-menu">
                  {this.renderLanguageSwitch()}
                  <div className="logout-wrapper">
                    <ClickableElement
                      className="clickable-hover__full-width"
                      minimumDelay={2000}
                      onClick={() => this.logoutFromDataConsent()}>
                      <span className="dropdown__option"><Trans>Logout</Trans></span>
                    </ClickableElement>
                  </div>
                </div>
              )
            }
          </header>
          <HeaderBanners />
        </React.Fragment>
        }
      </Translation>
    );
  }

  renderMobileLinks() {
    const { canAccessLending, canAccessSubscription } = this.props;
    const headerElements = [];
    const headerLinks = Header.getHeaderLinks(canAccessLending, canAccessSubscription);
    for (let i = 0; i < headerLinks.length; i += 1) {
      const currentLink = headerLinks[i];

      if (currentLink.children) {
        const childLinks = currentLink.children.map((childLink, idx) => this.renderMobileLink(childLink, `${i}-${idx}`));
        headerElements.push(...childLinks);
      } else {
        headerElements.push(this.renderMobileLink(currentLink, i));
      }
    }

    headerElements.push(<FireCampaignHeaderLink key={headerElements.length + 2} index={headerElements.length + 2} isMobile />);
    headerElements.push(<EthLuckyDrawHeaderLink key={headerElements.length + 3} index={headerElements.length + 3} isMobile />);
    headerElements.push(<BtcPizzaDayHeaderLink key={headerElements.length + 4} index={headerElements.length + 4} isMobile />);
    headerElements.push(<Bake4TheWinHeaderLink key={headerElements.length + 5} index={headerElements.length + 5} isMobile />);

    return headerElements;
  }

  // eslint-disable-next-line class-methods-use-this
  renderMobileLink(headerLink, index) {
    return <HeaderNavigationLink
      key={`header-link-${index}`}
      headerLink={headerLink}
      className={'header__mobile-menu-link'}
    />;
  }

  renderHeaderLinks() {
    const { canAccessLending, canAccessSubscription } = this.props;
    const headerElements = [];
    const headerLinks = Header.getHeaderLinks(canAccessLending, canAccessSubscription);
    for (let i = 0; i < headerLinks.length; i += 1) {
      const link = headerLinks[i];

      const headerElement = link.children ? this.renderDropdownHeader(link, i) : this.renderHeaderLink(link, i);
      headerElements.push(headerElement);
    }

    headerElements.push(<FireCampaignHeaderLink key={headerElements.length + 2} index={headerElements.length + 2} isMobile={false} />);
    headerElements.push(<EthLuckyDrawHeaderLink key={headerElements.length + 3} index={headerElements.length + 3} isMobile={false} />);
    headerElements.push(<BtcPizzaDayHeaderLink key={headerElements.length + 4} index={headerElements.length + 4} isMobile={false} />);
    headerElements.push(<Bake4TheWinHeaderLink key={headerElements.length + 5} index={headerElements.length + 5} isMobile={false} />);

    return headerElements;
  }

  // eslint-disable-next-line class-methods-use-this
  renderDropdownHeader(headerLink, idx) {
    return <HeaderDropdown key={idx} headerLink={headerLink} />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderHeaderLink(headerLink, index) {
    return <HeaderNavigationLink
      key={`header-link-${index}`}
      headerLink={headerLink}
      className={'link'}
    />;
  }

  renderDesktopHamburgerMenu() {
    return (
      <span className="header__dropdown-container">
        {this.renderBurgerUnauthenticated(true)}
      </span>
    );
  }

  renderMobileMenu() {
    const { userDetails: user } = this.props;
    const mobileMenuDropDown = user ? this.renderBurgerAuthenticated(false) : this.renderBurgerUnauthenticated(false);
    return (
      <span className="header__dropdown-container">
        {mobileMenuDropDown}
      </span>
    );
  }

  renderBurgerAuthenticated(isDesktopHamburgerMenu) {
    return (
      <DropDown
        alignment="bottomOffsetRight100"
        renderDropdown={() => this.renderMobileMenuAuthenticated(isDesktopHamburgerMenu)}
        hideMenuOnClick={true}
        keepOpenWhenClickOn="mobile-language-switch"
      >
        <span className="header__icon-container">
          <Icon name="hamburger" />
        </span>
      </DropDown>
    );
  }

  renderMobileMenuAuthenticated(isDesktopHamburgerMenu) {
    return !isDesktopHamburgerMenu && (
      <div className="header__mobile-menu extended-width">
        <div className="header__mobile-menu-container">
          {this.renderMobileLinks()}
        </div>
        <hr />
        <div className="mobile-language-switch">
          {this.renderLanguageSwitch()}
        </div>
      </div>
    );
  }

  renderBurgerUnauthenticated(isDesktopHamburgerMenu) {
    return (
      <DropDown
        alignment="bottomRight"
        renderDropdown={() => this.renderMobileMenuUnauthenticated(isDesktopHamburgerMenu)}
        hideMenuOnClick={true}
        keepOpenWhenClickOn="mobile-language-switch"
      >
        <span className="header__icon-container">
          <Icon name="hamburger" />
        </span>
      </DropDown>
    );
  }

  renderMobileMenuUnauthenticated(isDesktopHamburgerMenu) {
    const {
      buttonTitle,
      registerLink,
      trackingEventPayload,
    } = this.getRegisterLinkInfo();
    return (
      <div className="header__mobile-menu">
        {
          !isDesktopHamburgerMenu && (
            <>
              <div className="header__mobile-menu-container">
                {this.renderMobileLinks()}
              </div>
              <hr />
            </>
          )
        }
        <div className="mobile-language-switch">
          {this.renderLanguageSwitch()}
        </div>
        <hr />
        <div className="header__mobile-menu-container">
          <Link to="/login" className="header__mobile-menu-link">
            <Trans>Login</Trans>
          </Link>
          <Link to={registerLink} className="header__mobile-menu-link" onClick={() => { gtmTrack('trackEvent', trackingEventPayload); }}>
            <Trans>{buttonTitle}</Trans>
          </Link>
        </div>
      </div>
    );
  }

  renderUserMenu() {
    const { userDetails: user } = this.props;
    const userMenu = !user && this.renderUserMenuUnauthenticated();
    return (
      <div className="flex-vertical-center ml-3">
        {userMenu}
      </div>
    );
  }

  renderLanguageSwitch() {
    const { selectedLanguage } = this.props.languageRegionSettings;
    const defaultLanguage = languages.find(item => item.value === selectedLanguage) ?? languages[0];
    return <DropDown
      ref={(element) => { this.languageDropDownRef = element; }}
      renderDropdown={() => (
        <div className="language-dropdown-width">
          {
            languages.map(lng => <span key={lng.value} className="dropdown__option" onClick={() => this.onLanguageChange(lng.value)}>{lng.label}</span>)
          }
        </div>
      )}
      alignment='bottomRight'
    >
      <span className="header__language-container nowrap">
        <b>{defaultLanguage.label}</b> <i className="fa fa-angle-down"></i>
      </span>
    </DropDown>;
  }

  renderProfileMenu() {
    const { userDetails: user } = this.props;
    if (!user) {
      return null;
    }

    return (
      <div className="flex-vertical-center">
        {this.renderProfile()}
      </div>
    );
  }

  getRegisterLinkInfo = () => {
    const buttonTitle = 'Sign Up';
    const registerLink = '/register';
    const EventData = { button_click_text: buttonTitle, button_click_link: registerLink, button_place: 'Top Bar' };
    const trackingEventPayload = {
      event: iEventNames.initiate_sign_up,
      event_type: iEventTypes.custom,
      event_data: EventData,
    };
    return {
      buttonTitle,
      registerLink,
      EventData,
      trackingEventPayload,
    };
  }

  renderUserMenuUnauthenticated = () => {
    const {
      buttonTitle,
      registerLink,
      trackingEventPayload,
    } = this.getRegisterLinkInfo();
    return (
      <React.Fragment>
        <Link to="/login" className="link" onClick={() => this.props.setRedirectOnLogin(null)}><Trans>Login</Trans></Link>
        <Link to={registerLink} className="link" onClick={() => { gtmTrack('trackEvent', trackingEventPayload); this.props.setRedirectOnLogin(null); }}><Trans>{buttonTitle}</Trans></Link>
      </React.Fragment>
    );
  }

  renderProfile() {
    const { email } = this.props.userDetails;
    const { profileOpen } = this.state;
    return (
      <span className="header__dropdown-container">
        <DropDown
          alignment="bottomRight"
          renderDropdown={() => this.renderProfileDropdown()}
          isOpen={profileOpen}
          onToggle={isOpen => this.toggleDropDowns(PROFILE_TYPE, isOpen)}
          control
        >
          <img
            className="header__dropdown-avatar"
            src={`https://www.gravatar.com/avatar/${md5(email.toLowerCase())}?size=80&d=blank`}
            onError={(e: any) => { e.target.onerror = null; e.target.src = userDefaultPicture; }}
            alt="Profile Avatar" />
        </DropDown>
      </span>
    );
  }

  renderProfileDropdown() {
    return (
      <>
        <div className="profile-dropdown-width" onClick={this.closeMenus}>
          <Link to="/me" className="dropdown__option"><Trans>Profile and settings</Trans></Link>
          <Link to="/wallets" className="dropdown__option"><Trans>Balances</Trans></Link>
          <Link to="/transactions" className="dropdown__option"><Trans>Transactions</Trans></Link>
          <hr />
        </div>
        <div className="profile-language-menu">
          {this.renderLanguageSwitch()}
        </div>
        <hr />
        <div className="profile-dropdown-width" onClick={this.closeMenus}>
          <ClickableElement
            className="clickable-hover__full-width"
            minimumDelay={2000}
            onClick={() => this.logout()}>
            <span className="dropdown__option"><Trans>Logout</Trans></span>
          </ClickableElement>
        </div>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(Header);
